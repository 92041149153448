export const countries = [
    {code: 'AD', value: 'Andorra', phone: '376', flag: '🇦🇩' },
    { code: 'AE', value: 'United Arab Emirates', phone: '971', flag: '🇦🇪' },
    { code: 'AF', value: 'Afghanistan', phone: '93', flag: '🇦🇫' },
    { code: 'AG', value: 'Antigua and Barbuda', phone: '1-268', flag: '🇦🇬' },
    { code: 'AI', value: 'Anguilla', phone: '1-264', flag: '🇦🇮' },
    { code: 'AL', value: 'Albania', phone: '355', flag: '🇦🇱' },
    { code: 'AM', value: 'Armenia', phone: '374', flag: '🇦🇲' },
    { code: 'AO', value: 'Angola', phone: '244', flag: '🇦🇴' },
    { code: 'AQ', value: 'Antarctica', phone: '672', flag: '🇦🇶' },
    { code: 'AR', value: 'Argentina', phone: '54', flag: '🇦🇷' },
    { code: 'AS', value: 'American Samoa', phone: '1-684', flag: '🇦🇸' },
    { code: 'AT', value: 'Austria', phone: '43', flag: '🇦🇹' },
    { code: 'AU', value: 'Australia', phone: '61', flag: '🇦🇺' },
    { code: 'AW', value: 'Aruba', phone: '297', flag: '🇦🇼' },
    { code: 'AX', value: 'Aland Islands', phone: '358', flag: '🇦🇽' },
    { code: 'AZ', value: 'Azerbaijan', phone: '994', flag: '🇦🇿' },
    { code: 'BA', value: 'Bosnia and Herzegovina', phone: '387', flag: '🇧🇦' },
    { code: 'BB', value: 'Barbados', phone: '1-246', flag: '🇧🇧' },
    { code: 'BD', value: 'Bangladesh', phone: '880', flag: '🇧🇩' },
    { code: 'BE', value: 'Belgium', phone: '32', flag: '🇧🇪' },
    { code: 'BF', value: 'Burkina Faso', phone: '226', flag: '🇧🇫' },
    { code: 'BG', value: 'Bulgaria', phone: '359', flag: '🇧🇬' },
    { code: 'BH', value: 'Bahrain', phone: '973', flag: '🇧🇭' },
    { code: 'BI', value: 'Burundi', phone: '257', flag: '🇧🇮' },
    { code: 'BJ', value: 'Benin', phone: '229', flag: '🇧🇯' },
    { code: 'BL', value: 'Saint Barthelemy', phone: '590', flag: '🇧🇱' },
    { code: 'BM', value: 'Bermuda', phone: '1-441', flag: '🇧🇲' },
    { code: 'BN', value: 'Brunei Darussalam', phone: '673', flag: '🇧🇳' },
    { code: 'BO', value: 'Bolivia', phone: '591', flag: '🇧🇴' },
    { code: 'BQ', value: 'Bonaire, Sint Eustatius and Saba', phone: '599', flag: '🇧🇶' },
    { code: 'BR', value: 'Brazil', phone: '55', flag: '🇧🇷' },
    { code: 'BS', value: 'Bahamas', phone: '1-242', flag: '🇧🇸' },
    { code: 'BT', value: 'Bhutan', phone: '975', flag: '🇧🇹' },
    { code: 'BV', value: 'Bouvet Island', phone: '47', flag: '🇧🇻' },
    { code: 'BW', value: 'Botswana', phone: '267', flag: '🇧🇼' },
    { code: 'BY', value: 'Belarus', phone: '375', flag: '🇧🇾' },
    { code: 'BZ', value: 'Belize', phone: '501', flag: '🇧🇿' },
    { code: 'CA', value: 'Canada', phone: '1', flag: '🇨🇦' },
    { code: 'CC', value: 'Cocos (Keeling) Islands', phone: '61', flag: '🇨🇨' },
    { code: 'CD', value: 'Congo, Democratic Republic of the', phone: '243', flag: '🇨🇩' },
    { code: 'CF', value: 'Central African Republic', phone: '236', flag: '🇨🇫' },
    { code: 'CG', value: 'Congo', phone: '242', flag: '🇨🇬' },
    { code: 'CH', value: 'Switzerland', phone: '41', flag: '🇨🇭' },
    { code: 'CI', value: 'Cote d\'Ivoire', phone: '225', flag: '🇨🇮' },
    { code: 'CK', value: 'Cook Islands', phone: '682', flag: '🇨🇰' },
    { code: 'CL', value: 'Chile', phone: '56', flag: '🇨🇱' },
    { code: 'CM', value: 'Cameroon', phone: '237', flag: '🇨🇲' },
    { code: 'CN', value: 'China', phone: '86', flag: '🇨🇳' },
    { code: 'CO', value: 'Colombia', phone: '57', flag: '🇨🇴' },
    { code: 'CR', value: 'Costa Rica', phone: '506', flag: '🇨🇷' },
    { code: 'CU', value: 'Cuba', phone: '53', flag: '🇨🇺' },
    { code: 'CV', value: 'Cape Verde', phone: '238', flag: '🇨🇻' },
    { code: 'CW', value: 'Curacao', phone: '599', flag: '🇨🇼' },
    { code: 'CX', value: 'Christmas Island', phone: '61', flag: '🇨🇽' },
    { code: 'CY', value: 'Cyprus', phone: '357', flag: '🇨🇾' },
    { code: 'CZ', value: 'Czech Republic', phone: '420', flag: '🇨🇿' },
    { code: 'DE', value: 'Germany', phone: '49', flag: '🇩🇪' },
    { code: 'DJ', value: 'Djibouti', phone: '253', flag: '🇩🇯' },
    { code: 'DK', value: 'Denmark', phone: '45', flag: '🇩🇰' },
    { code: 'DM', value: 'Dominica', phone: '1-767', flag: '🇩🇲' },
    { code: 'DO', value: 'Dominican Republic', phone: '1-809', flag: '🇩🇴' },
    { code: 'DZ', value: 'Algeria', phone: '213', flag: '🇩🇿' },
    { code: 'EC', value: 'Ecuador', phone: '593', flag: '🇪🇨' },
    { code: 'EE', value: 'Estonia', phone: '372', flag: '🇪🇪' },
    { code: 'EG', value: 'Egypt', phone: '20', flag: '🇪🇬' },
    { code: 'EH', value: 'Western Sahara', phone: '212', flag: '🇪🇭' },
    { code: 'ER', value: 'Eritrea', phone: '291', flag: '🇪🇷' },
    { code: 'ES', value: 'Spain', phone: '34', flag: '🇪🇸' },
    { code: 'ET', value: 'Ethiopia', phone: '251', flag: '🇪🇹' },
    { code: 'FI', value: 'Finland', phone: '358', flag: '🇫🇮' },
    { code: 'FJ', value: 'Fiji', phone: '679', flag: '🇫🇯' },
    { code: 'FK', value: 'Falkland Islands (Malvinas)', phone: '500', flag: '🇫🇰' },
    { code: 'FM', value: 'Micronesia, Federated States of', phone: '691', flag: '🇫🇲' },
    { code: 'FO', value: 'Faroe Islands', phone: '298', flag: '🇫🇴' },
    { code: 'FR', value: 'France', phone: '33', flag: '🇫🇷' },
    { code: 'GA', value: 'Gabon', phone: '241', flag: '🇬🇦' },
    { code: 'GB', value: 'United Kingdom', phone: '44', flag: '🇬🇧' },
    { code: 'GD', value: 'Grenada', phone: '1-473', flag: '🇬🇩' },
    { code: 'GE', value: 'Georgia', phone: '995', flag: '🇬🇪' },
    { code: 'GF', value: 'French Guiana', phone: '594', flag: '🇬🇫' },
    { code: 'GG', value: 'Guernsey', phone: '44', flag: '🇬🇬' },
    { code: 'GH', value: 'Ghana', phone: '233', flag: '🇬🇭' },
    { code: 'GI', value: 'Gibraltar', phone: '350', flag: '🇬🇮' },
    { code: 'GL', value: 'Greenland', phone: '299', flag: '🇬🇱' },
    { code: 'GM', value: 'Gambia', phone: '220', flag: '🇬🇲' },
    { code: 'GN', value: 'Guinea', phone: '224', flag: '🇬🇳' },
    { code: 'GP', value: 'Guadeloupe', phone: '590', flag: '🇬🇵' },
    { code: 'GQ', value: 'Equatorial Guinea', phone: '240', flag: '🇬🇶' },
    { code: 'GR', value: 'Greece', phone: '30', flag: '🇬🇷' },
    { code: 'GS', value: 'South Georgia and the South Sandwich Islands', phone: '500', flag: '🇬🇸' },
    { code: 'GT', value: 'Guatemala', phone: '502', flag: '🇬🇹' },
    { code: 'GU', value: 'Guam', phone: '1-671', flag: '🇬🇺' },
    { code: 'GW', value: 'Guinea-Bissau', phone: '245', flag: '🇬🇼' },
    { code: 'GY', value: 'Guyana', phone: '592', flag: '🇬🇾' },
    { code: 'HK', value: 'Hong Kong', phone: '852', flag: '🇭🇰' },
    { code: 'HM', value: 'Heard Island and McDonald Islands', phone: '672', flag: '🇭🇲' },
    { code: 'HN', value: 'Honduras', phone: '504', flag: '🇭🇳' },
    { code: 'HR', value: 'Croatia', phone: '385', flag: '🇭🇷' },
    { code: 'HT', value: 'Haiti', phone: '509', flag: '🇭🇹' },
    { code: 'HU', value: 'Hungary', phone: '36', flag: '🇭🇺' },
    { code: 'ID', value: 'Indonesia', phone: '62', flag: '🇮🇩' },
    { code: 'IE', value: 'Ireland', phone: '353', flag: '🇮🇪' },
    { code: 'IL', value: 'Israel', phone: '972', flag: '🇮🇱' },
    { code: 'IM', value: 'Isle of Man', phone: '44', flag: '🇮🇲' },
    { code: 'IN', value: 'India', phone: '91', flag: '🇮🇳' },
    { code: 'IO', value: 'British Indian Ocean Territory', phone: '246', flag: '🇮🇴' },
    { code: 'IQ', value: 'Iraq', phone: '964', flag: '🇮🇶' },
    { code: 'IR', value: 'Iran, Islamic Republic of', phone: '98', flag: '🇮🇷' },
    { code: 'IS', value: 'Iceland', phone: '354', flag: '🇮🇸' },
    { code: 'IT', value: 'Italy', phone: '39', flag: '🇮🇹' },
    { code: 'JE', value: 'Jersey', phone: '44', flag: '🇯🇪' },
    { code: 'JM', value: 'Jamaica', phone: '1-876', flag: '🇯🇲' },
    { code: 'JO', value: 'Jordan', phone: '962', flag: '🇯🇴' },
    { code: 'JP', value: 'Japan', phone: '81', flag: '🇯🇵' },
    { code: 'KE', value: 'Kenya', phone: '254', flag: '🇰🇪' },
    { code: 'KG', value: 'Kyrgyzstan', phone: '996', flag: '🇰🇬' },
    { code: 'KH', value: 'Cambodia', phone: '855', flag: '🇰🇭' },
    { code: 'KI', value: 'Kiribati', phone: '686', flag: '🇰🇮' },
    { code: 'KM', value: 'Comoros', phone: '269', flag: '🇰🇲' },
    { code: 'KN', value: 'Saint Kitts and Nevis', phone: '1-869', flag: '🇰🇳' },
    { code: 'KP', value: "Korea, Democratic People's Republic of", phone: '850', flag: '🇰🇵' },
    { code: 'KR', value: 'Korea, Republic of', phone: '82', flag: '🇰🇷' },
    { code: 'KW', value: 'Kuwait', phone: '965', flag: '🇰🇼' },
    { code: 'KY', value: 'Cayman Islands', phone: '1-345', flag: '🇰🇾' },
    { code: 'KZ', value: 'Kazakhstan', phone: '7', flag: '🇰🇿' },
    { code: 'LA', value: "Lao People's Democratic Republic", phone: '856', flag: '🇱🇦' },
    { code: 'LB', value: 'Lebanon', phone: '961', flag: '🇱🇧' },
    { code: 'LC', value: 'Saint Lucia', phone: '1-758', flag: '🇱🇨' },
    { code: 'LI', value: 'Liechtenstein', phone: '423', flag: '🇱🇮' },
    { code: 'LK', value: 'Sri Lanka', phone: '94', flag: '🇱🇰' },
    { code: 'LR', value: 'Liberia', phone: '231', flag: '🇱🇷' },
    { code: 'LS', value: 'Lesotho', phone: '266', flag: '🇱🇸' },
    { code: 'LT', value: 'Lithuania', phone: '370', flag: '🇱🇹' },
    { code: 'LU', value: 'Luxembourg', phone: '352', flag: '🇱🇺' },
    { code: 'LV', value: 'Latvia', phone: '371', flag: '🇱🇻' },
    { code: 'LY', value: 'Libya', phone: '218', flag: '🇱🇾' },
    { code: 'MA', value: 'Morocco', phone: '212', flag: '🇲🇦' },
    { code: 'MC', value: 'Monaco', phone: '377', flag: '🇲🇨' },
    { code: 'MD', value: 'Moldova, Republic of', phone: '373', flag: '🇲🇩' },
    { code: 'ME', value: 'Montenegro', phone: '382', flag: '🇲🇪' },
    { code: 'MF', value: 'Saint Martin (French part)', phone: '590', flag: '🇲🇫' },
    { code: 'MG', value: 'Madagascar', phone: '261', flag: '🇲🇬' },
    { code: 'MH', value: 'Marshall Islands', phone: '692', flag: '🇲🇭' },
    { code: 'MK', value: 'Macedonia, the Former Yugoslav Republic of', phone: '389', flag: '🇲🇰' },
    { code: 'ML', value: 'Mali', phone: '223', flag: '🇲🇱' },
    { code: 'MM', value: 'Myanmar', phone: '95', flag: '🇲🇲' },
    { code: 'MN', value: 'Mongolia', phone: '976', flag: '🇲🇳' },
    { code: 'MO', value: 'Macao', phone: '853', flag: '🇲🇴' },
    { code: 'MP', value: 'Northern Mariana Islands', phone: '1-670', flag: '🇲🇵' },
    { code: 'MQ', value: 'Martinique', phone: '596', flag: '🇲🇶' },
    { code: 'MR', value: 'Mauritania', phone: '222', flag: '🇲🇷' },
    { code: 'MS', value: 'Montserrat', phone: '1-664', flag: '🇲🇸' },
    { code: 'MT', value: 'Malta', phone: '356', flag: '🇲🇹' },
    { code: 'MU', value: 'Mauritius', phone: '230', flag: '🇲🇺' },
    { code: 'MV', value: 'Maldives', phone: '960', flag: '🇲🇻' },
    { code: 'MW', value: 'Malawi', phone: '265', flag: '🇲🇼' },
    { code: 'MX', value: 'Mexico', phone: '52', flag: '🇲🇽' },
    { code: 'MY', value: 'Malaysia', phone: '60', flag: '🇲🇾' },
    { code: 'MZ', value: 'Mozambique', phone: '258', flag: '🇲🇿' },
    { code: 'NA', value: 'Namibia', phone: '264', flag: '🇳🇦' },
    { code: 'NC', value: 'New Caledonia', phone: '687', flag: '🇳🇨' },
    { code: 'NE', value: 'Niger', phone: '227', flag: '🇳🇪' },
    { code: 'NF', value: 'Norfolk Island', phone: '672', flag: '🇳🇫' },
    { code: 'NG', value: 'Nigeria', phone: '234', flag: '🇳🇬' },
    { code: 'NI', value: 'Nicaragua', phone: '505', flag: '🇳🇮' },
    { code: 'NL', value: 'Netherlands', phone: '31', flag: '🇳🇱' },
    { code: 'NO', value: 'Norway', phone: '47', flag: '🇳🇴' },
    { code: 'NP', value: 'Nepal', phone: '977', flag: '🇳🇵' },
    { code: 'NR', value: 'Nauru', phone: '674', flag: '🇳🇷' },
    { code: 'NU', value: 'Niue', phone: '683', flag: '🇳🇺' },
    { code: 'NZ', value: 'New Zealand', phone: '64', flag: '🇳🇿' },
    { code: 'OM', value: 'Oman', phone: '968', flag: '🇴🇲' },
    { code: 'PA', value: 'Panama', phone: '507', flag: '🇵🇦' },
    { code: 'PE', value: 'Peru', phone: '51', flag: '🇵🇪' },
    { code: 'PF', value: 'French Polynesia', phone: '689', flag: '🇵🇫' },
    { code: 'PG', value: 'Papua New Guinea', phone: '675', flag: '🇵🇬' },
    { code: 'PH', value: 'Philippines', phone: '63', flag: '🇵🇭' },
    { code: 'PK', value: 'Pakistan', phone: '92', flag: '🇵🇰' },
    { code: 'PL', value: 'Poland', phone: '48', flag: '🇵🇱' },
    { code: 'PM', value: 'Saint Pierre and Miquelon', phone: '508', flag: '🇵🇲' },
    { code: 'PN', value: 'Pitcairn', phone: '870', flag: '🇵🇳' },
    { code: 'PR', value: 'Puerto Rico', phone: '1', flag: '🇵🇷' },
    { code: 'PS', value: 'Palestine, State of', phone: '970', flag: '🇵🇸' },
    { code: 'PT', value: 'Portugal', phone: '351', flag: '🇵🇹' },
    { code: 'PW', value: 'Palau', phone: '680', flag: '🇵🇼' },
    { code: 'PY', value: 'Paraguay', phone: '595', flag: '🇵🇾' },
    { code: 'QA', value: 'Qatar', phone: '974', flag: '🇶🇦' },
    { code: 'RE', value: 'Reunion', phone: '262', flag: '🇷🇪' },
    { code: 'RO', value: 'Romania', phone: '40', flag: '🇷🇴' },
    { code: 'RS', value: 'Serbia', phone: '381', flag: '🇷🇸' },
    { code: 'RU', value: 'Russian Federation', phone: '7', flag: '🇷🇺' },
    { code: 'RW', value: 'Rwanda', phone: '250', flag: '🇷🇼' },
    { code: 'SA', value: 'Saudi Arabia', phone: '966', flag: '🇸🇦' },
    { code: 'SB', value: 'Solomon Islands', phone: '677', flag: '🇸🇧' },
    { code: 'SC', value: 'Seychelles', phone: '248', flag: '🇸🇨' },
    { code: 'SD', value: 'Sudan', phone: '249', flag: '🇸🇩' },
    { code: 'SE', value: 'Sweden', phone: '46', flag: '🇸🇪' },
    { code: 'SG', value: 'Singapore', phone: '65', flag: '🇸🇬' },
    { code: 'SH', value: 'Saint Helena', phone: '290', flag: '🇸🇭' },
    { code: 'SI', value: 'Slovenia', phone: '386', flag: '🇸🇮' },
    { code: 'SJ', value: 'Svalbard and Jan Mayen', phone: '47', flag: '🇸🇯' },
    { code: 'SK', value: 'Slovakia', phone: '421', flag: '🇸🇰' },
    { code: 'SL', value: 'Sierra Leone', phone: '232', flag: '🇸🇱' },
    { code: 'SM', value: 'San Marino', phone: '378', flag: '🇸🇲' },
    { code: 'SN', value: 'Senegal', phone: '221', flag: '🇸🇳' },
    { code: 'SO', value: 'Somalia', phone: '252', flag: '🇸🇴' },
    { code: 'SR', value: 'Suriname', phone: '597', flag: '🇸🇷' },
    { code: 'SS', value: 'South Sudan', phone: '211', flag: '🇸🇸' },
    { code: 'ST', value: 'Sao Tome and Principe', phone: '239', flag: '🇸🇹' },
    { code: 'SV', value: 'El Salvador', phone: '503', flag: '🇸🇻' },
    { code: 'SX', value: 'Sint Maarten (Dutch part)', phone: '1-721', flag: '🇸🇽' },
    { code: 'SY', value: 'Syrian Arab Republic', phone: '963', flag: '🇸🇾' },
    { code: 'SZ', value: 'Swaziland', phone: '268', flag: '🇸🇿' },
    { code: 'TC', value: 'Turks and Caicos Islands', phone: '1-649', flag: '🇹🇨' },
    { code: 'TD', value: 'Chad', phone: '235', flag: '🇹🇩' },
    { code: 'TF', value: 'French Southern Territories', phone: '262', flag: '🇹🇫' },
    { code: 'TG', value: 'Togo', phone: '228', flag: '🇹🇬' },
    { code: 'TH', value: 'Thailand', phone: '66', flag: '🇹🇭' },
    { code: 'TJ', value: 'Tajikistan', phone: '992', flag: '🇹🇯' },
    { code: 'TK', value: 'Tokelau', phone: '690', flag: '🇹🇰' },
    { code: 'TL', value: 'Timor-Leste', phone: '670', flag: '🇹🇱' },
    { code: 'TM', value: 'Turkmenistan', phone: '993', flag: '🇹🇲' },
    { code: 'TN', value: 'Tunisia', phone: '216', flag: '🇹🇳' },
    { code: 'TO', value: 'Tonga', phone: '676', flag: '🇹🇴' },
    { code: 'TR', value: 'Turkey', phone: '90', flag: '🇹🇷' },
    { code: 'TT', value: 'Trinidad and Tobago', phone: '1-868', flag: '🇹🇹' },
    { code: 'TV', value: 'Tuvalu', phone: '688', flag: '🇹🇻' },
    { code: 'TW', value: 'Taiwan', phone: '886', flag: '🇹🇼' },
    { code: 'TZ', value: 'United Republic of Tanzania', phone: '255', flag: '🇹🇿' },
    { code: 'UA', value: 'Ukraine', phone: '380', flag: '🇺🇦' },
    { code: 'UG', value: 'Uganda', phone: '256', flag: '🇺🇬' },
    { code: 'US', value: 'United States', phone: '1', flag: '🇺🇸' },
    { code: 'UY', value: 'Uruguay', phone: '598', flag: '🇺🇾' },
    { code: 'UZ', value: 'Uzbekistan', phone: '998', flag: '🇺🇿' },
    { code: 'VA', value: 'Holy See (Vatican City State)', phone: '379', flag: '🇻🇦' },
    { code: 'VC', value: 'Saint Vincent and the Grenadines', phone: '1-784', flag: '🇻🇨' },
    { code: 'VE', value: 'Venezuela', phone: '58', flag: '🇻🇪' },
    { code: 'VG', value: 'British Virgin Islands', phone: '1-284', flag: '🇻🇬' },
    { code: 'VI', value: 'US Virgin Islands', phone: '1-340', flag: '🇻🇮' },
    { code: 'VN', value: 'Vietnam', phone: '84', flag: '🇻🇳' },
    { code: 'VU', value: 'Vanuatu', phone: '678', flag: '🇻🇺' },
    { code: 'WF', value: 'Wallis and Futuna', phone: '681', flag: '🇼🇫' },
    { code: 'WS', value: 'Samoa', phone: '685', flag: '🇼🇸' },
    { code: 'XK', value: 'Kosovo', phone: '383', flag: '🇽🇰' },
    { code: 'YE', value: 'Yemen', phone: '967', flag: '🇾🇪' },
    { code: 'YT', value: 'Mayotte', phone: '262', flag: '🇾🇹' },
    { code: 'ZA', value: 'South Africa', phone: '27', flag: '🇿🇦' },
    { code: 'ZM', value: 'Zambia', phone: '260', flag: '🇿🇲' },
    { code: 'ZW', value: 'Zimbabwe', phone: '263', flag: '🇿🇼' }
  ];